<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('update:show', false)"
    @keydown.esc="$emit('update:show', false)"
    width="1200px"
  >
    <v-card style="overflow: hidden" :loading="loading">
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.devolucao") + " de " }}
        {{ $tc("global.venda") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:show', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <div class="d-flex justify-space-between">
          <span class="text-subtitle-1">
            {{ $tc("global.cliente") + ": " + devolucao.cliente_nome }}
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.deposito") + ": " + devolucao.deposito }}
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.valor") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ devolucao.valor | guarani }}
            </span>
            <span v-else>
              {{ devolucao.valor | currency }}
            </span>
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.desconto") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ devolucao.desconto | guarani }}
            </span>
            <span v-else>
              {{ devolucao.desconto | currency }}
            </span>
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.total") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ total_com_desconto | guarani }}
            </span>
            <span v-else>
              {{ total_com_desconto | currency }}
            </span>
          </span>
        </div>
        <v-row class="mt-4">
          <v-col v-if="gerar_nota_credito" cols="6">
            <v-select
              v-model="selectedTimbrado"
              :items="timbrados"
              :loading="loadingTimbrado"
              item-text="timbrado"
              item-value="timbrado_id"
              hide-details
              outlined
              dense
              :required="gerar_nota_credito ? true : false"
              :label="$tc('global.timbrado')"
            >
              <template v-slot:selection="{ item }">
                <span>{{
                  `${item.descricao ? item.descricao : ""} ${item.timbrado} `
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{
                  `${item.descricao ? item.descricao : ""} ${item.timbrado} `
                }}</span>
              </template></v-select
            >
          </v-col>
        </v-row>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="
            devolucaoProp ? devolucao.devolucao_itens : devolucao.venda_items
          "
          :items-per-page="-1"
          hide-default-footer
          class="data-tables pt-8"
          @item-selected="itemSelecionado"
          @toggle-select-all="allItemsSelecionado"
        >
          <template v-slot:item.preco="{ item }">
            {{ devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ item.preco | guarani }}
            </span>
            <span v-else>
              {{ item.preco | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>
          <template v-slot:item.devolucao="{ item, index }">
            <div class="d-flex align-center pa-2" :key="i">
              <v-form @submit.prevent ref="form" v-model="validForm">
                <v-text-field
                  v-model="item.devolucao"
                  dense
                  hide-details
                  filled
                  disabled
                  :error="
                    Number(item.devolucao) >
                    Number(item.qtde) - Number(item.qtde_devolucao)
                      ? true
                      : false
                  "
                  @blur="changeQnt(item.devolucao, index)"
                  class="input-center"
                ></v-text-field>
              </v-form>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <div class="pr-4 py-4 text-right">
        <div>
          <span class="tocs_gray_3--text"
            >Total {{ " " + $tc("global.devolucao") }}:
          </span>
          {{ devolucao.moeda_sigla }}
          <span v-if="devolucao.moeda_sigla === 'G$'">
            {{ total_devolucao | guarani }}
          </span>
          <span v-else>
            {{ total_devolucao | currency }}
          </span>
        </div>
      </div>
      <v-row class="mx-4">
        <v-col cols="4">{{ $tc("global.selecioneFormaDevolucao") }}:</v-col>
        <v-col cols="8" class="align-start">
          <v-radio-group
            v-model="devolucao.forma_devolucao"
            hide-details
            row
            class="pa-0 ma-0"
          >
            <v-radio disabled label="Dinheiro" value="dinheiro"></v-radio>
            <v-radio disabled label="Amortizar" value="amortizar"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="pb-6">
        <v-row v-if="devolucao.forma_devolucao == 'dinheiro'" class="mx-4">
          <v-col cols="4"></v-col>
          <v-col cols="6" class="body-2 tocs_gray_3--text">
            {{ $tc("global.devolucaoDinheiro") }}
          </v-col>
        </v-row>
        <v-row v-if="devolucao.forma_devolucao == 'amortizar'" class="mx-4">
          <v-col cols="4"></v-col>
          <v-col cols="6" class="body-2 tocs_gray_3--text">{{
            $tc("global.devolucaoAmortizar")
          }}</v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchVendaDevolucao } from "@/api/vendas/vendas.js";
import { devolverCompra, devolverVenda } from "@/api/devolucoes/devolucoes.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
import { fetchCaixasFaturamento } from "@/api/caixas/caixas.js";
import { dataImpressaoFatura, fetchFatura } from "@/api/faturas/faturas.js";

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );

const importFaturaAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples_auto_impressor.js"
  );

export default {
  name: "DialogDevolucao",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item_id: {
      type: Number,
      default: null,
    },
    tipo_devolucao: {
      type: String,
    },
    devolucaoProp: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      devolucao: {},
      validForm: true,
      formRules: [inputRequired],
      itemSelecionados: 0,
      i: 0,
      selectedCaixa: null,
      caixas: [],
      total_devolucao: 0,
      total_custo: 0,
      gerar_nota_credito: false,
      selectedTimbrado: null,
      timbrados: [],
      loadingTimbrado: false,
      forma_devolucao: "dinheiro",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          width: "400px",
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.qtdDevolvida"),
          value: "qtde_devolucao",
        },
        {
          text: this.$tc("global.preco"),
          align: "right",
          value: "preco",
        },
        {
          text: this.$tc("global.total"),
          align: "right",
          value: "total",
        },
        {
          text: this.$tc("global.devolucao"),
          width: "150px",
          align: "center",
          value: "devolucao",
        },
      ];
    },
    total_com_desconto() {
      return Number(this.devolucao.valor) - Number(this.devolucao.desconto);
    },
  },

  watch: {
    gerar_nota_credito() {
      if (this.gerar_nota_credito) {
        this.getTimbrados();
      }
    },
  },

  methods: {
    async getTimbrados() {
      this.loadingTimbrado = true;
      return fetchUserTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.loadingTimbrado = false;
        })
        .catch((error) => {
          this.loadingTimbrado = false;
        });
    },
    totalDevolucao() {
      this.total_devolucao = 0;
      if (this.devolucao && this.devolucao.devolucao_itens) {
        this.devolucao.devolucao_itens.forEach((item) => {
          this.total_devolucao += Number(item.devolucao) * Number(item.preco);
          this.total_custo += Number(item.cif_atual) * Number(item.qtde);
        });
      }
    },
    async getVendaDevolucao() {
      this.loading = true;
      await fetchVendaDevolucao(this.item_id)
        .then((response) => {
          this.devolucao = response;
          this.devolucao.venda_items.map((item) => {
            item.total =
              (Number(item.qtde) - Number(item.qtde_devolucao)) *
              Number(item.preco);
            if (Number(item.qtde) - Number(item.qtde_devolucao) > 0) {
              item.isSelectable = true;
            } else {
              item.isSelectable = false;
            }
            item.devolucao = 0;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCaixasFaturamento() {
      this.loading = true;
      fetchCaixasFaturamento(`?moeda_id=${this.devolucao.moeda_id}`)
        .then((response) => {
          this.caixas = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    if (this.devolucaoProp) {
      this.devolucao = this.devolucaoProp;
      this.selectedCaixa = {
        id: this.devolucao.caixa_id,
        descricao: this.devolucao.caixa_descricao,
      };
      this.totalDevolucao();
    }
    if (this.tipo_devolucao === "vendas") {
      await this.getVendaDevolucao();
    }
    this.getCaixasFaturamento();
  },
};
</script>

<style scoped lang="scss">
.input-center ::v-deep input {
  text-align: center;
}
</style>
